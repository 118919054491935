<template>
  <section>
    <div class="catalogue">
      <img src="/img/product-design-title.svg" alt="" />
      <img src="/img/strategy.png" alt="" />
      <img src="/img/design.png" alt="" />
      <img src="/img/produce.png" alt="" />
    </div>

    <img src="/img/catalogue-left-background.svg" alt="" class="left" />
    <img src="/img/catalogue-right-background.svg" alt="" class="right" />
  </section>
</template>

<style scoped>
section {
  width: 100%;
  padding: 5rem 2rem;
  position: relative;
  overflow: hidden;

  background-image: url('/img/catalogue-background.svg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.catalogue {
  max-width: 71rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

.catalogue img {
  width: 100%;
  margin: auto;
  pointer-events: none;
}

.left {
  width: 2.5rem;
  position: absolute;
  left: 0;
  top: 0;
}

.right {
  width: 2.5rem;
  position: absolute;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1050px) {
  .catalogue img {
    max-width: 30rem;
    margin: auto;
    pointer-events: none;
  }
}

@media only screen and (max-width: 800px) {
  .left,
  .right {
    display: none;
  }

  .catalogue {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .up {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 650px) {
}
</style>
