<template>
  <section>
    <div class="hover">
      <div class="left-part forWeb">
        <h1 @mouseenter="changeImg('/img/merch-all-img.png')">ALL</h1>
        <h1 @mouseenter="changeImg('/img/merch-hoodies-img.png')">HOODIES</h1>
        <h1 @mouseenter="changeImg('/img/merch-shirt-img.png')">SHIRTS</h1>
        <h1 @mouseenter="changeImg('/img/merch-hat-img.png')">HAT</h1>
        <h1 @mouseenter="changeImg('/img/merch-more-img.png')">MORE</h1>
      </div>
      <div class="left-part forMobile">
        <h1 @mouseenter="changeImgMob('/img/merch-all-img-mobile.png')">ALL</h1>
        <h1 @mouseenter="changeImgMob('/img/merch-hoodies-img-mobile.png')">
          HOODIES
        </h1>
        <h1 @mouseenter="changeImgMob('/img/merch-shirt-img-mobile.png')">
          SHIRTS
        </h1>
        <h1 @mouseenter="changeImgMob('/img/merch-hat-img-mobile.png')">HAT</h1>
        <h1 @mouseenter="changeImgMob('/img/merch-more-img-mobile.png')">
          MORE
        </h1>
      </div>
      <div class="right-part forWeb">
        <img id="slider" :src="img" alt="" />
      </div>
      <div class="right-part forMobile">
        <img id="slider" :src="imgMobile" alt="" />
      </div>

      <img src="/img/merch-bottom-img.svg" alt="" class="bottom" />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      img: '/img/merch-all-img.png',
      imgMobile: '/img/merch-all-img-mobile.png',
    };
  },

  methods: {
    changeImg: function (img) {
      this.img = img;
    },
    changeImgMob: function (imgMobile) {
      this.imgMobile = imgMobile;
    },
  },
};
</script>

<style scoped>
.forMobile {
  display: none;
}

section {
  width: 100%;
  padding: 5rem 0;
  overflow: hidden;
  position: relative;

  background-color: black;
}

.hover {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 5rem;
  max-width: 73rem;
  margin: auto;
}

.hover .left-part h1 {
  margin: 0;
  font-size: 6rem;
  font-weight: 700;
  color: black;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  transition: 0.2s ease-in;
}

.hover .left-part h1:hover {
  color: white;
  cursor: pointer;
}

.left-part {
  padding-left: 2rem;
}

.right-part img {
  width: 70rem;
  margin: auto;
  pointer-events: none;
}

.bottom {
  position: absolute;
  left: 10%;
  right: 10%;
  bottom: 0;
}

@media only screen and (max-width: 1050px) {
  .forMobile {
    display: block;
  }

  .forWeb {
    display: none !important;
  }

  .hover {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .left-part {
    padding: 0 2rem;
    display: flex;
    margin: auto;
    gap: 1.2rem;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }

  .hover .left-part h1 {
    font-size: 3.5rem;
    font-weight: 500;
    -webkit-text-stroke-width: 0.5px;
  }

  .right-part img {
    width: 100%;
    display: block;
  }
}

@media only screen and (max-width: 650px) {
  .hover .left-part h1 {
    font-size: 2rem;
    font-weight: 500;
  }
}
</style>
