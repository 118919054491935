<template>
  <div>
    <section>
      <div class="sku">
        <h1>Merch Fulfillment as a Service</h1>
        <h2>We are offering PHP 10,000 initial capital for your merch!</h2>
        <div class="fulfillment-details">
          <div class="left-part">
            <p>End-to-end service for top events</p>
            <div class="for-web">
              <p>
                <span style="margin-right: 0.5rem">🎽</span>
                Merch strategy & design
              </p>
              <p>
                <span style="margin-right: 0.5rem">🤝</span>
                Supplier scouting & negotiation
              </p>
              <p>
                <span style="margin-right: 0.5rem">📦</span>
                Packaging
              </p>
              <p>
                <span style="margin-right: 0.5rem">🚚</span>
                Storage & delivery
              </p>
            </div>
          </div>
        </div>
        <img
          src="img/aryn-shirt.png"
          alt="Avatar"
          class="aryn-tshirt stickers"
        />
        <img
          src="img/full-stickers.png"
          alt="Avatar"
          class="full-stickers stickers"
        />
      </div>
    </section>
  </div>
</template>

<style scoped>
section {
  width: 100%;
  padding: 3rem 2rem;
  text-align: center;
  overflow: hidden;

  background-image: url('/img/merch-fulfillment-background.png');
  background-size: 150%;
  background-repeat: no-repeat;
  background-position: center center;
}

.sku {
  max-width: 60rem;
  margin: auto;
  text-align: center;
  padding: 6rem 0;
  position: relative;
}

.sku h1 {
  margin: 0 0 1rem 0;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color: black;
}

.sku h2 {
  color: black;
  max-width: 40rem;
  margin: auto;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.fulfillment-details {
  max-width: 45rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  z-index: 9;
}

.left-part {
  display: grid;
  gap: 1rem;
  padding: 2rem;
  z-index: 999999999;
  border-radius: 5px;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.left-part > p {
  font-weight: 600;
  font-size: 1.5rem;
  text-align: left;
}

.for-web {
  text-align: left;
  align-items: center;
  display: grid;
  gap: 0;
}

.for-web p {
  line-height: 1;
  font-size: 1.5rem;
  font-weight: 600;
}

.aryn-tshirt {
  width: 28rem;
  right: -12.5rem;
  bottom: 1rem;
  transform: rotate(10deg);
  position: absolute;
  pointer-events: none;
}

.full-stickers {
  width: 15rem;
  left: -5.5rem;
  bottom: 1rem;
  transform: rotate(-10deg);
  position: absolute;
  pointer-events: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 1000px) {
  section {
    background-size: 200%;
  }
}

@media only screen and (max-width: 800px) {
  .fulfillment-details {
    grid-template-columns: 1fr;
  }

  .sku h1 {
    text-align: center;
    font-size: 1.5rem;
  }

  .right-part img {
    max-width: 30rem;
  }

  .hide {
    display: none;
  }

  .left-part > p {
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.3;
  }

  .for-web {
    justify-content: center;
  }

  .for-web p {
    font-size: 0.7rem;
    line-height: 1.5;
  }

  .for-web p span {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  section {
    background-size: 400%;
  }
}
</style>
