<template>
  <div>
    <HeaderNavHomepage />
    <main>
      <div class="main-info">
        <h5>INSIGHTS</h5>
        <h1>SPART FARNACIO</h1>
        <p>Youtube Creator</p>
      </div>
    </main>

    <div class="blog-details">
      <div class="blog-details-info">
        <section>
          <div class="details">
            <i class="fa-solid fa-camera svg-icon"></i>
            <p class="subtitle">
              Top adventure content creator in the Philippines.
            </p>
          </div>
          <div class="details">
            <i class="fa-solid fa-people-group svg-icon"></i>
            <p class="subtitle">2.1M Followers on Facebook.</p>
          </div>
          <div class="details">
            <i class="fa-solid fa-money-bill-trend-up svg-icon"></i>
            <p class="subtitle">
              Over ₱250,000 earned for charities in just 2 weeks.
            </p>
          </div>
        </section>

        <!-- THE GOAL -->
        <section>
          <h5 style="margin-top: 5rem" class="title">THE GOAL</h5>
          <p class="subtitle">
            As an adventurous and ambitious content creator, Spart Farnacio’s
            primary goal is to inspire others, especially the youth, to not be
            afraid to dream big and chase after those dreams. He also aspires to
            expand his platform and earnings to create more out-of-the-box
            content and to further give back to his community and those in need.
          </p>
        </section>

        <!-- THE STORY -->
        <section>
          <h5 class="title">THE STORY</h5>
          <p class="subtitle">
            Spart Farnacio is a 24-year-old vlogger who has a huge following on
            Facebook and YouTube. He usually posts challenge and adventure vlogs
            catered to Filipino viewers. Some of his top-viewed videos include
            his toy car adventure vlogs which disrupted the internet and helped
            him become the star he is today. As he aimed to create more content
            revolving around this iconic concept, he started the Toy Car Series
            Project with the goal of reaching Pagudpud in the Northernmost part
            of the Philippines from Metro Manila using his toy car in 7 days - a
            journey of over 550 kilometers! Aside from that, he also had a goal
            of raising money for charities.
          </p>
        </section>

        <!-- Raising $$$ to Fund the Toy Car Series Project -->
        <section>
          <h5 class="title">Raising $$$ to Fund the Toy Car Series Project</h5>
          <p class="subtitle">
            A week before his epic project to travel to the end of the
            Philippine archipelago using just a toy car, he sold his greetings
            and fan interactions to raise the budget for the trip. He offered
            shoutouts, video calls, dates, his signature straw hat, and even a
            trip to Boracay with him and other creators for his future content.
            These were offered at varying prices ranging from Php 100 to 15,000.
          </p>
        </section>

        <!-- VIDEO -->
        <section>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/he7kXdfBItc?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </section>

        <!-- ROAD TO PAGUPUD BEGINS -->
        <section>
          <h5 class="title">Road to Pagudpud Begins</h5>
          <p class="subtitle">
            As he and his team started their trip to the North, they offered
            daily challenges with exclusive slots replenished each day.
            <br />
            <br />
            The challenges varied in complexity as well as price. There were
            challenges to decide Spart’s food for that particular day, and where
            the challenger decides what and how much of a particular skin
            product Spart will put on his body for the entire day (choices
            included glue, peel-off mask, or sunblock). There were also
            offerings for business promotions where Spart promised to include
            their logos on his toy car and do a quick advertisement of their
            businesses in his daily vlogs.
          </p>
        </section>

        <!-- TWO IMAGES -->
        <section
          style="display: grid; grid-template-columns: 1fr 1fr; gap: 1rem"
          class="images"
        >
          <img src="/img/spart1-min.png" alt="" />
          <img src="/img/spart2-min.png" alt="" />
        </section>

        <!-- New Achievement: Unlocked! -->
        <section>
          <h5 class="title">New Achievement: Unlocked!</h5>
          <p class="subtitle">
            After 7 days, he was able to reach Pagudpud in 7 days and raise over
            Php 300,000 along the way. As he set foot in the city, he was warmly
            greeted by fans and supporters, and even organized a meet and greet
            with him.
            <br />
            <br />
            He was commended by a lot of people for his courage to take on that
            challenge and even share his earnings with people who need them the
            most.
          </p>
        </section>

        <!-- What’s Next for Spart Universe -->
        <section>
          <h5 class="title">What’s Next for Spart Universe</h5>
          <p class="subtitle">
            Weeks after his epic trip, he went to the headquarters of the
            charities and officially handed over his donation. He recently
            released a video about it and has also introduced his subscriptions
            to the viewers. His 50-pesos subscriptions are now in pre-selling
            mode, as he prepares to release his exclusive content by September
            16.
          </p>
        </section>

        <!-- HelixPay’s Role in Building Spart Universe -->
        <section>
          <h5 class="title">HelixPay’s Role in Building Spart Universe</h5>
          <p class="subtitle">
            Spart took on this exciting journey with HelixPay. He is able to
            expand his platform and create a whole e-commerce experience for his
            supporters using HelixPay’s technology – from his website powered by
            HelixPay’s storefront design tools, smooth checkout experience
            facilitated by HelixPay’s payment processing technology, to how he
            is able to interact with his fans through email blasts, interactive
            displays on his website, and his exclusive content which is soon to
            be posted on his members’ page on his HelixPay storefront.
            <br />
            <br />
            As Spart takes on more amazing projects, HelixPay also continuously
            develops its tools to enable the visions of excellent creators like
            him.
          </p>
        </section>
      </div>
    </div>

    <MainCCFooter />
  </div>
</template>

<script>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainCCFooter from '@/components/MainCCFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

export default {
  name: 'Spart',
  components: {
    HeaderNavHomepage,
    MainCCFooter,
  },

  setup() {
    useHead(
      buildPageMeta({
        title: 'Spart | Ecommerce Technology for Creators & Communities',
        description:
          'Select option that is the best fit for your business needs. HelixPay Checkout is custom built for subscriptions and recurring payments.',
        url: 'https://www.helixpay.ph/spart',
        image: 'https://www.helixpay.ph/img/spart-meta-min.png',
      })
    );
  },
};
</script>

<style scoped>
section {
  max-width: 50rem;
  margin: auto;
  padding: 0 2rem;
}

main {
  background-image: url('/img/rubicon.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30% 60%;
}

.main-info {
  max-width: 50rem;
  margin: auto;
  padding: 10rem 2rem;
  color: white;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
}

.main-info h5 {
  font-size: 1rem;
  font-weight: 700;
}

.main-info h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

.main-info p {
  font-size: 1rem;
}

.details {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.details i {
  text-align: center;
  width: 6%;
  margin-right: 10px;
  opacity: 0.5;
}

.details p {
  margin-top: 1rem;
}

.svg-icon {
  font-size: 2rem;
  margin-top: 0;
  padding-top: 0;
  opacity: 1;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #ff5f8f;
  margin-top: 3rem;
}

.subtitle {
  line-height: 2;
  font-weight: 500;
  opacity: 0.5;
}

iframe {
  width: 100%;
  height: 450px;
  margin-top: 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.images img {
  width: 100%;
}

.blog-details {
  width: 100%;
}

.blog-details-info {
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media only screen and (max-width: 650px) {
  .details {
    gap: 2rem;
  }
}
</style>
