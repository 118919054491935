<template>
  <section>
    <div class="partners">
      <div class="left-part">
        <p>Seamless Deliveries</p>
        <h1>Working with the Best Logistic Partners</h1>
        <p>
          HelixPay coordinates with the leading logistics delivery companies in
          the Philippines.
        </p>
      </div>
      <div class="right-part">
        <img src="img/partners-grab.svg" alt="Avatar" />
        <img src="img/partners-lalamove.svg" alt="Avatar" />
        <img src="img/partners-borzo.svg" alt="Avatar" />
        <img src="img/partners-entrego.svg" alt="Avatar" />
        <img src="img/partners-ninjavan.svg" alt="Avatar" />
        <img src="img/partners-jt.svg" alt="Avatar" />
      </div>
    </div>
  </section>
</template>

<style scoped>
section {
  width: 100%;
  padding: 9rem 2rem;
}

.partners {
  max-width: 71rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 5rem;
}

.left-part h1 {
  color: #25a4e1;
  margin: 0;
  font-size: 3.5rem;
  font-weight: 700;
}

.left-part p:nth-child(1) {
  font-size: 1.5rem;
  font-weight: 600;
  opacity: 0.5;
}

.left-part p:nth-child(3) {
  margin: 1rem 0;
  font-weight: 600;
  opacity: 0.5;
}

.right-part {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
}

.right-part img {
  display: flex;
  margin: auto;
  justify-content: center;
  width: 80%;
  pointer-events: none;
}

@media only screen and (max-width: 950px) {
  section {
    padding: 4rem 2rem;
  }

  .partners {
    grid-template-columns: 1fr;
    gap: 5rem;
    text-align: center;
  }

  .left-part h1 {
    font-size: 2rem;
  }

  .left-part p:nth-child(1) {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 800px) {
}
</style>
