<template>
  <div>
    <section>
      <div class="main-info">
        <h1>Estimate your income</h1>
        <p>Estimate your potential earnings with our fan monetization tool.</p>
      </div>
    </section>

    <div class="hero">
      <Pricing />
    </div>
  </div>
</template>

<script>
import Pricing from '@/components/Pricing.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

export default {
  name: 'KumuClub',
  components: {
    Pricing,
  },

  setup() {
    useHead(
      buildPageMeta({
        title: 'Kumu | More Ways to Earn with Own E-Commerce Platform',
        description:
          'Content Creators use HelixPay Ecommerce Technology to generate hundreds of thousands of pesos in earnings from their fans and community.',
        url: 'https://www.helixpay.ph/kumuclub',
        // image:  'https://www.helixpay.ph/img/helixpay_ticketing.jpg',
      })
    );
  },
};
</script>

<style scoped>
section {
  width: 100%;
  background-image: url('/img/calc-wallpaper.jpg');
  background-position: 50% 50%;
  background-size: cover;
  padding: 12rem 0rem;
}

.main-info {
  display: none;
  max-width: 70rem;
  padding: 0 2rem;
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 1050px) {
  section {
    padding: 7rem 0rem;
  }
}

@media only screen and (max-width: 650px) {
}
</style>
